/* About Container */
.about-container {
    background-color: #f8f8f8; /* Background color for the container */
    padding: 40px;
    text-align: center;
  }
  
  /* About Content */
  .about-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  /* About Text */
  .about-text {
    max-width: 600px; /* Maximum width for the text content */
    margin-bottom: 20px;
    color: #333; /* Text color */
  }
  
  /* About Image */
  .about-image img {
    max-width: 100%; /* Ensure the image fits within its container */
    border-radius: 50%; /* Apply a rounded border to the image for a modern look */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle box shadow */
  }
  
  /* About Heading */
  .about-text h1 {
    font-size: 32px; /* Font size for the heading */
    margin-bottom: 20px;
    color: #333; /* Heading color */
  }
  
  /* About Paragraphs */
  .about-text p {
    font-size: 16px; /* Font size for paragraphs */
    line-height: 1.5;
    margin-bottom: 20px;
  }
  
  /* Media Query for Responsive Design */
  @media (max-width: 768px) {
    /* Adjust styles for smaller screens */
    .about-content {
      flex-direction: column; /* Stack content on smaller screens */
    }
  
    .about-text {
      text-align: center; /* Center-align text on smaller screens */
    }
  }
  