.table-row {
    border: 2px solid royalblue;
    border-radius: 8px;
    background-color: transparent;
    /* Add other styles as needed */
  }
  
.added-flights-info {
  justify-self: center;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Global Styles */
body {
  font-family: 'Your Chosen Font', sans-serif; /* Replace with your chosen font */
  margin: 0;
  padding: 0;
}

/* Container for Results */
.results-container {
  background-color: #f5f5f5; /* Use a light background color */
  /* padding: 1em; */
  gap: 1em;
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

/* Header Styling */
.results-header {
  /* background-color: #f5f5f5; */
  text-align: center;
  width: 100%;
}

/* Flight Card Styling */
.flight-card {
  background-color: #fff; /* Use a white background for cards */
  border: 2px solid #282c34;
  border-radius: 10px;
  margin: 20px 0; /* Increase vertical spacing between cards */
  padding: 20px;
  /* width: 90%; */
  width: 200px; /* Set the width to your desired size */
  height: 150px; /* Set the height to your desired size */
  max-width: 100%; /* Allow the card to take the full width of its container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a drop shadow */
  transition: transform 0.3s ease; /* Add a hover effect */
}

.flight-card:hover {
  transform: translateY(-2px); /* Lift the card slightly on hover */
}

.flight-details {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px; /* Add vertical spacing between flight details */
}

.label {
  font-weight: bold;
  text-align: left;
  flex: 1;
}

.truncate-text {
  white-space: nowrap;       /* Prevent text from wrapping */
  overflow: hidden;          /* Hide overflowing text */
  text-overflow: ellipsis;   /* Display ellipsis for truncated text */
  max-width: 10ch;           /* Set the maximum width to 10 characters */
}

.value {
  text-align: right;
  flex: 1;
}

.book-button-container {
  text-align: center;
  display: flex; /* Use flexbox to arrange buttons horizontally */
  align-items: center; /* Center vertically within the container */
  justify-content: center;
  padding-top: 15px;
}

/* Styles for the "Added" button */
.book-button.added {
  background-color: #ccc; /* Gray background */
  color: #666; /* Darker gray text color */
  cursor: not-allowed; /* Disable the pointer cursor */
  pointer-events: none; /* Disable click events */
}

.book-button {
  background-color: #ff6b00; /* Use an orange button color */
  color: #fff; /* White text color */
  border: none;
  border-radius: 5px;
  padding: 10px 10px; /* Increase button padding for a larger button */
  width: 85%; /* Make the button wider */
  cursor: pointer;
  transition: background-color 0.3s ease; /* Add a hover effect */
}

.book-button:hover {
  background-color: #e54e00; /* Darker orange on hover */
}

.spin-wheel-button {
  display: block;
  width: fit-content;
  margin: 20px auto; /* Adjust the margin to position the button */
  padding: 10px 20px;
  background-color: #4287f5;
  color: white;
  text-align: center;
  font-size: larger;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.spin-wheel-button:hover {
  background-color: #282c34; /* Change color on hover */
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}

/* Apply the "pulse" animation when the button has the "pulse" class */
.spin-wheel-button.pulse {
  animation: pulse 1s infinite;
}

.spin-wheel-button.disabled {
  background-color: #ccc; /* Gray background */
  color: #666; /* Darker gray text color */
  cursor: not-allowed; /* Disable the pointer cursor */
  pointer-events: none; /* Disable click events */
}

/* Add styles for the "X" button */
.remove-button {
  background-color: #ccc; /* Gray background */
  color: #666; /* Darker gray text color */
  border: none;
  border-radius: 50%; /* Make it a circle */
  width: 24px; /* Set the width and height as needed */
  height: 24px;
  font-size: 14px; /* Adjust font size as needed */
  cursor: pointer;
  margin-left: 10px; /* Add some spacing between the "Added" button and the "X" button */
  vertical-align: middle; /* Align the "X" button vertically in the middle with the text */
}

/* Style the "X" button on hover */
.remove-button:hover {
  background-color: #999; /* Darker gray background on hover */
  color: #333; /* Darker text color on hover */
}


