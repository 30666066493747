.wheel-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.spin-button {
  display: block;
  width: fit-content;
  margin: 20px auto; /* Adjust the margin to position the button */
  padding: 10px 20px;
  background-color: #282c34;
  color: white;
  text-align: center;
  font-size: larger;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.spin-button.disabled {
  background-color: #fff; /* Gray background */
  color: #fff; /* Darker gray text color */
  cursor: not-allowed; /* Disable the pointer cursor */
  pointer-events: none; /* Disable click events */
}

.spinning-wheel-container {
  position: fixed;
  top: 43.5px;
  left: -106px;
  border: clear; /* Add the black outline */
  width: 550px; /* Set the width of the box */
  height: 550px; /* Set the height of the box */
  border: 3px solid #73AD21;
  /* height: 100vh;
  width: 100vh; */
}

.wheel {
  position: relative;
  /* top: 150px;
  left: 150px; */
}

.winner-text {
  position: relative;
  text-align: center; 
  top: 50px;
  font-size: 1.5em;
}

.winner-text-bold {
  position: relative;
  text-align: center; 
  font-weight: bolder;
  font-size: 1.5em;
  top: 50px;
}

.purchase-tickets-button-container {
  position: relative;
  text-align: center; 
}

/* Style the "Purchase Tickets" button */
.purchase-tickets-button {
  background-color: #282c34; /* Button background color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 20px 30px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-size: large;
}

.purchase-tickets-button.clear {
  background-color: #fff; /* Button background color */
  color: white; /* Button text color */
  border: none;
  border-radius: 5px;
  padding: 20px 30px;
  top: -300px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  font-size: large;
  
}

.link-text {
  text-align: center; 
  padding-top: 1rem;
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
.spin-button.pulse {
  animation: pulse 1s infinite;
}

/* .purchase-tickets-button:hover {
  background-color: #3366cc; 
} */

@media (max-width: 600px) {
  .winner-text {
    position: fixed;
    text-align: center; 
    top: 70px;
    left: 130px;
    font-size: 1.5em;
  }
  
  .winner-text-bold {
    position: fixed;
    text-align: center; 
    font-weight: bolder;
    font-size: 1.5em;
    top: 100px;
    left: 130px;
  }

  .purchase-tickets-button-container {
    position: fixed;
    top: 600px;
    left: 75px;
    text-align: center; /* Center the button horizontally */
  }
  .spinning-wheel-container {
    position: fixed;
    top: 43.5px;
    left: -106px;
    border: clear; /* Add the black outline */
    width: 550px; /* Set the width of the box */
    height: 550px; /* Set the height of the box */
    /* height: 100vh;
    width: 100vh; */
  }
  
  .hide-text-box {
    position: absolute;
    width: 200px;
    height: 70px;
    top: 507px;
    left: 100px;
    /* border: 3px solid #73AD21; */
    background-color: #fff;
  }
  
  .wheel {
    position: relative;
    /* top: 150px;
    left: 150px; */
  }
}

.loading-spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #282c34;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


