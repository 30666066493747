.App-header {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #282c34;
  color: white;
  padding: 0.5rem; /* Reduced padding */
  /* Add or adjust other properties as needed */
}

.App-header h1 {
  margin: 0;
  font-size: 1.5rem; /* Adjust font size if necessary */
}

.menu-icon {
  position: absolute;
  left: 1rem;
  /* Adjust styles for your hamburger icon here if needed */
}


.hero-container {
  position: relative;
  margin-top: 3rem;
}

.centered-image {
  display: block; /* Makes the image a block element */
  margin: 0 auto; /* Centers the image horizontally */
  padding-top: 3rem;
}

.error-message {
  color: red;
  margin-top: 10px;
}

.hero-image {
  width: 100%;
  height: auto;
  filter: blur(3px);
  margin-top: -3rem;
}

/* Adjust the search-form container */
.search-form {
  position: absolute;
  top: calc(1em + 30px); /* Adjusted top value */
  left: 50%;
  transform: translateX(-50%);
  width: 80%; /* Adjust this value as needed */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.search-form input, 
.search-form select {
  width: 100%; /* Full width of the form container */
  padding: 0.5rem; /* Padding inside the fields */
  margin-bottom: 1rem; /* Vertical space between fields */
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box; /* Include padding and border in the element's total width */
}

/* For the last input/select element, you might want to remove the bottom margin */
.search-form input:last-child, 
.search-form select:last-child {
  margin-bottom: 0;
}

/* Mobile device adjustments, if needed */
@media (max-width: 600px) {
  .search-form {
    width: 90%; /* Adjusted width for mobile devices */
  }
}

.search-form input[type="date"] {
  width: 100%; /* Set a consistent width for date inputs */
  height: auto;
  padding: 0.5rem; /* Adjust padding as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
}

.search-form input[name="currency"] {
  width: 100%; /* Set a consistent width for date inputs */
  height: auto;
  padding: 0.5rem; /* Adjust padding as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: white;
  box-sizing: border-box;
}

.go-button {
  padding: 0.5rem 1rem;
  background-color: #f85427; /* Example color, change as needed */
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 1rem; /* Space above the button */
  width: 20%; /* Optional: If you want the button to take the full width */
  box-sizing: border-box; /* To include padding and border in the button's width */
}

.go-button.disabled {
  background-color: #ccc; /* Gray background */
  color: #666; /* Darker gray text color */
  cursor: not-allowed; /* Disable the pointer cursor */
  pointer-events: none; /* Disable click events */
}

.go-button:hover {
  background-color: #ff0000; /* Darker shade for hover, adjust as needed */
}

/* Add this to your CSS file */
.spinner {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(0, 0, 0, 0.3);
  border-top: 3px solid #282c34;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



